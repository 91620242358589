.listContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.listWrapper {
    width: 100%;
    max-width: 1024px;
    display: flex;
    gap: 20px;
}
.listSearch {
    flex: 1;
    background-color: #febb02;
    padding: 10px;
    border-radius: 10px;
    position: sticky;
    height: max-content;
}
.lsTtitle {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
}
.lsItem {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
}
.lsItem label {
    font-size: 12px;
}
.lsItem>input {
    height: 30px;
    bottom: none;
    padding: 5px;
}
.lsItem>span {
    height: 30px;
    bottom: none;
    padding: 5px;
    background-color: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.lsOptionItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    color: #333;
    font-size: 12px;
}
.lsOptionInput {
    width: 50px;
}
.listSearch>button{
    padding: 10px;
    background-color: #0071c2;
    border: none;
    color: #fff;
    font-weight: 600;
    width: 100%;
    cursor: pointer;
}
.listResult {
    flex: 3;
}
