.header {
    background-color: #003580;
    color: #fff;
    display: flex;
    justify-content: center;
    position: relative;
}
.headerContainer {
    width: 100%;
    max-width: 1024px;
    margin: 20px 0 100px 0;
}
.headerContainer.listMode {
    margin: 20px 0 0 0;
}
.headerList {
    display: flex;
    gap: 40px;
    margin-bottom: 50px;
}
.headerListItem {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}
.headerListItem.active {
    border: 1px solid #fff;
    padding: 10px;
    border-radius: 20px;
}

.headerDescription {
    margin: 20px 0;
}
.headerBtn {
    background-color: #0071c2;
    color: #fff;
    border:none;
    padding: 10px;
    cursor: pointer;
    font-weight: 500;

}
.headerSearch {
    height: 30px;
    background-color: #fff;
    border: 3px solid #febb02;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px 0px ;
    border-radius: 5px;
    position: absolute;
    bottom: -25px;
    width: 100%;
    max-width: 1024px;
    z-index: 2;
}
.headerIcon {
    color: lightgrey;
}
.headerSearchItem {
    display: flex;
    align-items: center;
    gap: 10px;
}
.headerSearchInput {
    border: none;
    outline: none;
}
.headerSearchText {
    color: lightgray;
    cursor: pointer;
}
.date {
    position: absolute;
    top: 52px;
}
.options {
    position: absolute;
    top: 52px;
    background-color: #fff;
    color: gray;
    border-radius: 5px;
    box-shadow:  0 3px 18px 0 rgba(0,0,0,0.12),0 3px 5px -1px rgba(0,0,0,0.2);
}
.optionItem {
    width: 200px;
    display: flex;
    justify-content: space-between;
    margin: 10px;
}
.optionCounter {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    color: #000;
}

.optionCounterButton {
    width: 30px;
    height: 30px;
    border: 1px solid #0071c2;
    color: #0071c2;
    cursor: pointer;
    background-color: #fff;
}
.optionCounterButton:disabled {
    cursor: not-allowed;
}
