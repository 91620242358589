.mail {
    width: 100%;
    margin-top: 50px;
    background-color: #003580;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 50px; 
}
.mailInputContainer>input{
    width: 300px;
    height: 30px;
    padding: 10px;
    border:none;
    margin-right: 10px;
    border-radius: 5px;
}
.mailInputContainer>button{
    height: 50px;
    background-color: #0071c2;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}