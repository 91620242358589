.footer {
width: 100%;
max-width: 1024px;
font-size: 12px;
}
.fLists {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 50px;
}
.fList {
    list-style: none;
    padding: 0;
}
.fListItem {
    margin-bottom: 10px;
    color: #003580;
    cursor: pointer;
}
.fcopy>p {
    text-align: center;
}