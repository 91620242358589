.featured {
    width: 100%;
    max-width: 1024px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
.featuredItem {
    position: relative;
    color: #fff;;
   
    border-radius: 10px;
    overflow: hidden;
    height: 250px;
}
.featuredImg {
    width: 100%;
    object-fit: cover;
    
}
.featuredTitles {
    position: absolute;
    bottom: 20px;
    left: 20px;
    margin-top: 10px;
}
