.propertylist {
    width: 100%;
    max-width: 1024px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
.propertyListItem {
   cursor: pointer;
    overflow: hidden;
    height: 100%;
    flex: 1;
}
.propertyListImg {
    width: 100%;
    object-fit: cover;
    border-radius: 3px;
}
.propertyListTitles {
    margin-top: 10px;
}
.propertyListTitles h3 {
    font-size: 17px;
}
.propertyListTitles p {
    font-weight: 300;
}