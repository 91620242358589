.homeContainer {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    overflow: hidden;
}
.homeTitle {
    width: 1024px;
   
}
