.fp {
    width: 100%;
    max-width: 1024px;
    display: flex;
    justify-content: center;
    gap: 20px;
}
.fpItem {
    flex: 1;
    gap: 10px;
    display: flex;
    flex-direction: column;
   
}
.fpImg {
    width: 100%;
}
.fpName {
   font-weight: bold;

}
.fpCity {
    font-weight: 300;
}
.fpPrice {
    font-weight: 500;
}
.fpRating>button {
    background-color: #003580;
    color: #fff;
    padding: 3px 5px; 
    margin-right: 10px;
    border: none;
    font-weight: 600;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.fpRating>span{
    font-size: 14px;
}